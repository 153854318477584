import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  compose,
  variant as ssVariant,
  color,
  typography,
  space,
  display,
  border,
} from "styled-system";

import theme from "../../utils/theme";
import Spinner from "./spinner";
import { Absolute } from "../containers/position";

const Root = styled.button`
  ${compose(
    space,
    display,
    color,
    typography,
    border,
  )};

  position: relative;
  cursor: pointer;
  transition: ${p => p.theme.transitionDefault};

  ${p => p.loading && `
    > div:first-of-type {
      visibility: hidden;
    }  
  `}

  ${ssVariant({
    variants: {
      primary: {
        color: theme.white,
        bg: theme.green,
        "font-family": theme.headingFont,
        "text-align": `center`,
        "border-radius": `3px`,
        padding: theme.btnPadL,
      },
    },
  })};

  ${ssVariant({
    prop: `loading`,
    variants: {
      true: {
        opacity: `0.8`,
        "pointer-events": `none`,
      },
    },
  })};
`;


const Button = ({ variant, children, loading, ...rest }) => {
  return (
    <Root variant={variant} loading={loading} {...rest}>
      <div>
        {children}
      </div>

      {loading && (
        <Absolute left="45%" top={`calc(50% - 14px)`}>
          <Spinner />
        </Absolute>
      )}
    </Root>
  );
};


Button.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
  loading: PropTypes.bool,
  fontSize: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  lineHeight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
};

export default Button;