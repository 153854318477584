import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Svg = styled.svg`
  fill: ${p => p.fill};
  display: inline-block;
  heigth: auto;
  width: ${p => p.width};

  
  ${p => p.size === `ttLarge`
    && `
      height: auto;
      width: 105px;

      @media (min-width: ${p.theme.screenL}) {
        width: 156px;
      }
    `}

  ${p => p.size === `ttSmall`
    && `
      height: 27px;
      width: 27px;
    `}

    ${p => p.size === `xxs`
      && `
    height: auto;
    width: 12px;
  `}

    ${p => p.size === `xs`
      && `
      height: auto;
      width: 16px;
    `}

    ${p => p.size === `s`
      && `
        height: auto;
        width: 20px;
      `}

    ${p => p.size === `m`
      && `
        height: auto;
        width: 46px;
      `}

      ${p => p.size === `l`
        && `
        height: auto;
        width: 60px;
      `}

      ${p => p.size === `xl`
        && `
        height: auto;
        width: 87px;
      `}
`;

const Icon = ({ children, title, fill, height, width, size, viewBox }) => {
  return (
    <Svg
      fill={fill}
      width={width}
      height={height}
      size={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
    >
      <title>{title}</title>
      {children}
    </Svg>
  );
};

Icon.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  title: PropTypes.string,
  fill: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  size: PropTypes.string,
  viewBox: PropTypes.string,
};

export default Icon;
