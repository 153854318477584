// NAVIGATION LINKS
export const navLinks = [
  {
    id: 0,
    path: `/coach-portal`,
    name: `For Coaches`,
    type: `link`,
  },
  {
    id: 1,
    path: `/parent-portal`,
    name: `For Parents`,
    type: `link`,
  },
  {
    id: 2,
    path: `/pricing`,
    name: `Pricing`,
    type: `link`,
  },
  {
    id: 3,
    path: `/faq`,
    name: `FAQs`,
    type: `link`,
  },
  {
    id: 4,
    path: `/blog`,
    name: `Blog`,
    type: `link`,
  },
  {
    id: 5,
    path: `/contact`,
    name: `Contact Us`,
    type: `link`,
  },
];

export const footerLinks = [
  ...navLinks,
  {
    id: 5,
    path: `/privacy`,
    name: `Privacy Policy`,
    type: `link`,
  },
  {
    id: 6,
    path: `/terms`,
    name: `Terms`,
    type: `link`,
  },
  {
    id: 7,
    path: `https://app.toptekkers.com/login`,
    name: `Login`,
  },
  {
    id: 8,
    path: `https://app.toptekkers.com`,
    name: `Register`,
  },
];
