import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { StaticQuery, graphql } from "gatsby";

import Flex from "../containers/flex";
import Image from "../globals/image";
import { AppleAppBadge } from "../svg/logos";

const Root = styled(Flex)`
  flex-wrap: wrap;
  align-content: center;

  a {
    margin-bottom: 6px;
    margin-right: 4px;
    width: ${p => p.itemWidth ? p.itemWidth : `133px !important`};

    &:last-of-type {
      margin-right: 0;
    }
  }

  svg {
    text-align: right;
  }

  ${p => p.vert
    && `
    flex-direction: row;

      @media (min-width: ${p.theme.screenS}) {
        text-align: right;
        flex-direction: column;

        > * {
          margin: 0 10px 6px 0;
          margin-right: 0;
        }
        svg {
          margin-left: auto;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }

    `}
`;

export const appBadges = graphql`
  fragment appBadges on File {
    childImageSharp {
      fluid(maxWidth: 133) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

const AppstoreLinks = ({ itemWidth, vert, ...rest }) => {
  return (
    <StaticQuery
      query={graphql`
        {
          google: file(relativePath: { eq: "images/appstore-google.png" }) {
            ...appBadges
          }
          apple: file(relativePath: { eq: "images/appstore-apple.png" }) {
            ...appBadges
          }
        }
      `}
      render={(data) => {
        const google = data.google.childImageSharp.fluid;
        const apple = data.apple.childImageSharp.fluid;

        return (
          <Root vert={vert} itemWidth={itemWidth} {...rest}>
            <a
              href="https://play.google.com/store/apps/details?id=com.toptekkers.toptekkers&hl=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image fluid={google} alt="Google Play app store badge" />
              {/* <AppleAppBadge size="m" /> */}
            </a>
            <a
              href="https://apps.apple.com/us/app/toptekkers/id1472343177?ls=1"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image fluid={apple} alt="Apple app store badge" />
            </a>
          </Root>
        );
      }}
    />
  );
};

AppstoreLinks.propTypes = {
  vert: PropTypes.bool,
  itemWidth: PropTypes.string,
};

export default AppstoreLinks;
