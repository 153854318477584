import styled from "styled-components";
import { compose, flexbox } from "styled-system";

import Box from "./box";

const Flex = styled(Box)`
  ${compose(
    flexbox,
  )}
`;

Flex.defaultProps = {
  display: `flex`,
};
export default Flex;
