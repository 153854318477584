import React, { useState } from "react";
import styled, { withTheme } from "styled-components";
import PropTypes from "prop-types";

import { Link } from "gatsby";
import NavbarLinks from "./navbarLinks";
import MobileMenuButton from "./mobileMenuButton";
import { TopTekkersLogo } from "../../svg/logos";
import LoginRegisterLinks from "../../links/loginRegisterLinks";

const NavWrapper = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  max-width: 1600px;

  > div:last-of-type {
    display: none;
  }

  @media (min-width: ${p => p.theme.screenL}) {
    padding: 0 30px;

    > button {
      display: none;
    }

    div:last-of-type {
      display: inline-block;
    }
  }
`;

const Navbar = ({ isMenuOpen, onMobileMenuButtonClick, theme }) => {
  return (
    <React.Fragment>
      <NavWrapper>
        <Link to="/">
          <TopTekkersLogo size="ttLarge" fill={theme.blueDark} />
        </Link>
        <NavbarLinks />

        <MobileMenuButton
          active={isMenuOpen}
          onClick={onMobileMenuButtonClick}
        />

        <LoginRegisterLinks isMenuOpen={isMenuOpen} />
      </NavWrapper>
    </React.Fragment>
  );
};

Navbar.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
  onMobileMenuButtonClick: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(Navbar);
