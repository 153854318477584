import styled from "styled-components";
import PropTypes from "prop-types";
import { Absolute } from "../containers/position";

const Circle = styled(Absolute)`
  border-radius: 50%;
  opacity: ${p => p.opacity};
  background-color: ${p => p.bgColor};

  /* static sizes */
  ${p => p.size === `xs`
    && `
    width: 21px;
    height: 21px;
  `}
  ${p => p.size === `s`
  && `
    width: 57px;
    height: 57px;
  `}
  ${p => p.size === `m`
    && `
    width: 93px;
    height: 93px;
  `}
  ${p => p.size === `l`
    && `
    width: 166px;
    height: 166px;
  `} 
  ${p => p.size === `xl`
    && `
    width: 266px;
    height: 266px;
  `} 

  /* Responsive sizes */
  ${p => p.grow
    && `
    width: 57px;
    height: 57px;

     @media (min-width: ${p.theme.screenM}) {
        width: 93px;
        height: 93px;
    }
  @media (min-width: ${p.theme.screenL}) {
      width: 166px;
      height: 166px;
    }
  `}


  ${p => p.growMid
    && `
    width: 57px;
    height: 57px;
  
    @media (min-width: ${p.theme.screenM}) {
      width: 93px;
      height: 93px;
    }
  `}

  ${p => p.growLarge
    && `
    width: 57px;
    height: 57px;
  
    @media (min-width: ${p.theme.screenM}) {
      width: 93px;
      height: 93px;
    }
    @media (min-width: ${p.theme.screenL}) {
      width: 166px;
      height: 166px;
    }
    @media (min-width: ${p.theme.screenXL}) {
      width: 266px;
      height: 266px;
    }
  `}

    ${p => p.hideOnMobile
      && `
      display: none;

      @media (min-width: ${p.theme.screenM}) {
        display: block;
      }
  `}
  ${p => p.hideOnTablet
      && `
      display: block;

      @media (min-width: ${p.theme.screenM}) {
        display: none;
      }
  `}
  ${p => p.desktopOnly
    && `
    display: none;

    @media (min-width: ${p.theme.screenXL}) {
      display: block;
    }
  `}
`;

Circle.defaultProps = {
  zIndex: 0,
};

Circle.propTypes = {
  bgColor: PropTypes.string.isRequired,
  opacity: PropTypes.string,
};

export default Circle;
