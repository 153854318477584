import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";

import { Link } from "gatsby";
import Flex from "../../containers/flex";
import Text from "../../typography/text";
import { footerLinks } from "../../../utils/content/siteNavigation";
import {
  TopTekkersLogoSmall,
  FacebookLogo,
  TwitterLogo,
  InstagramLogo,
} from "../../svg/logos";
import AppStoreLinks from "../../links/appstoreLinks";

const Root = styled.footer`
  width: 100%;
  padding: 30px 30px;
  background-color: ${p => p.theme.blueDark};
  color: ${p => p.theme.white};
  font-size: ${p => p.theme.rem18};

  @media (min-width: ${p => p.theme.screenL}) {
    padding-top: 45px;
    padding-bottom: 70px;
    font-size: ${p => p.theme.rem20};
  }

  > * {
    max-width: 1200px;
    margin: 0 auto;
  }
`;

const SiteLinks = styled.ul`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 200px;
  max-width: 230px;
  text-align: left;
  font-size: ${p => p.theme.rem16};
  margin-bottom: 20px;

  @media (min-width: ${p => p.theme.screenM}) {
    max-height: 150px;
    max-width: 472px;
  }

  @media (min-width: ${p => p.theme.screenL}) {
    margin-bottom: 30px;
    max-height: 170px;
  }

  li {
    margin-bottom: 14px;
    margin-right: 30px;
  }

  a {
    text-decoration: none;
    color: ${p => p.theme.white};

    @media (min-width: ${p => p.theme.screenL}) {
      margin-right: 60px;
    }
  }
`;

const LogoLinks = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  @media (min-width: ${p => p.theme.screenL}) {
    margin-bottom: 35px;
  }

  a {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  svg {
    vertical-align: middle;
  }
`;

const Footer = ({ theme }) => {
  const newDate = new Date();
  const currentYear = newDate.getFullYear();

  return (
    <Root>
      <LogoLinks>
        <TopTekkersLogoSmall size="ttSmall" fill={theme.white} />

        <div>
          <a
            href="https://www.facebook.com/TopTekkers"
            target="_blank"
            rel="noopener noreferer"
          >
            <FacebookLogo size="s" fill={theme.white} />
          </a>
          <a
            href="https://www.instagram.com/toptekkersapp"
            target="_blank"
            rel="noopener noreferer"
          >
            <InstagramLogo size="s" fill={theme.white} />
          </a>
          <a
            href="https://www.twitter.com/TopTekkersApp"
            target="_blank"
            rel="noopener noreferer"
          >
            <TwitterLogo size="s" fill={theme.white} />
          </a>
        </div>
      </LogoLinks>

      <Flex justifyContent="space-between" flexDirection={[`column`, `row`]}>
        <SiteLinks>
          {footerLinks.map((link, index) => {
            return (
              <li key={index}>
                {link.type === `link` ? (
                  <Link to={link.path} key={link.id}>
                    {link.name}
                  </Link>
                ) : (
                  <a href={link.path} key={link.id}>
                    {link.name}
                  </a>
                )}
              </li>
            );
          })}
        </SiteLinks>

        <AppStoreLinks vert />
      </Flex>

      <Text
        fontSize="13px"
        fontColor="rgba(255, 255, 255, 0.5)"
        margin="16px 0 0 0"
      >
        &copy; Copyright {currentYear}, The Coaching Manual
      </Text>
    </Root>
  );
};

Footer.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(Footer);
