import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Root = styled.div`
  white-space: nowrap;

  a {
    display: inline-block;
    padding: 10px 20px;
    font-size: ${p => p.theme.rem16};
    border-radius: ${p => p.theme.borderRadiusRndBtn};
    transition: ${p => p.theme.transitionDefault};
  }
`;

const LoginBtn = styled.a`
  color: ${p => p.theme.green};
  background-color: ${p => p.theme.white};
  border: 2px solid ${p => p.theme.green};
  margin-right: 10px;

  &:hover {
    color: ${p => p.theme.greenDark};
    border-color: ${p => p.theme.greenDark};
  }

  ${p => p.isMenuOpen && `
    color: ${p.theme.white};
    background-color: ${p.theme.green};
    border: 2px solid ${p.theme.white};
    margin-right: 10px;

    &:hover {
      color: ${p.theme.grey}
      border-color: ${p.theme.grey};
    }
  `}
  `;

const RegisterBtn = styled.a`
  color: ${p => p.theme.white};
  background-color: ${p => p.theme.green};
  border: 2px solid ${p => p.theme.green};


  &:hover {
    color: ${p => p.theme.white};
    background-color: ${p => p.theme.greenDark};
    border-color: ${p => p.theme.greenDark};
  }

  ${p => p.isMenuOpen && `
    color: ${p.theme.green};
    background-color: ${p.theme.white};;
    border: 2px solid ${p.theme.white};

    &:hover {
      color: ${p.theme.green}
      border: 2px solid ${p.theme.grey};
      background-color: ${p.theme.grey};
      border-color: ${p.theme.grey};
    }
  `}
  `;

const LoginRegisterButtons = ({ isMenuOpen }) => {
  return (
    <Root>
      <LoginBtn href="https://app.toptekkers.com/login" isMenuOpen={isMenuOpen}>Login</LoginBtn>

      <RegisterBtn href="https://app.toptekkers.com" isMenuOpen={isMenuOpen}>Register</RegisterBtn>
    </Root>
  );
};

LoginRegisterButtons.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
};

export default LoginRegisterButtons;
