import styled from "styled-components";
import { Link as GatsbyLink } from "gatsby";
import { compose, fontSize, fontFamily, color } from "styled-system";
import theme from "../../utils/theme";

const Link = styled(GatsbyLink)`
  ${compose(
    fontSize,
    fontFamily,
    color,
  )}

  transition: ${p => p.theme.transitionDefault};

  &:hover {
    color: ${p => p.hoverColor || `none`};
  }
`;

Link.defaultProps = {
  color: `#666666`,
  fontSize: [18, null, null, null, 20],
};

export default Link;
