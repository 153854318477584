import styled from "styled-components";
import { compose, variant, typography, space, maxWidth, display, color } from "styled-system";
import theme from "../../utils/theme";

const Text = styled.p(
  compose(
    typography,
    space,
    maxWidth,
    display,
    color,
  ),

  variant({
    variants: {
      readMore: {
        color: theme.green,
        fontSize: `18px`,
        transition: theme.transitionDefault,
        "&:hover": {
          color: theme.greenDark,
        },
      },
    },
  }),
);

Text.defaultProps = {
  color: `#666666`,
  lineHeight: [`32px`, null, null, `38px`],
  mb: [`20px`, null, null, null, `40px`],
};

export default Text;
