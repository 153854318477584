import React from "react";
import PropTypes from "prop-types";
import Navbar from "../nav/navbar";

const Header = ({ isMenuOpen, onMobileMenuButtonClick }) => {
  return (
    <header>
      <Navbar isMenuOpen={isMenuOpen} onMobileMenuButtonClick={onMobileMenuButtonClick} />
    </header>
  );
};

Header.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
  onMobileMenuButtonClick: PropTypes.func.isRequired,
};

export default Header;
