import styled, { withTheme } from "styled-components";

const Spinner = styled.div`
  @keyframes load {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  height: 30px;
  width: 30px;
  background-color: transparent;
  display: inline-block;
  border-radius: 50%;
  border-top: 3px solid ${p => p.color || p.theme.white};
  border-right: 3px solid rgba(255, 255, 255, 0.2);
  border-bottom: 3px solid rgba(255, 255, 255, 0.2);
  border-left: 3px solid rgba(255, 255, 255, 0.2);
  animation: load 1s infinite cubic-bezier(0.4, 0, 0.2, 1);
`;

export default withTheme(Spinner);
