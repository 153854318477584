import styled from "styled-components";
import Box from "./box";


export const Relative = styled(Box)([]);

Relative.defaultProps = {
  position: `relative`,
};

export const Fixed = styled(Box)([]);

Fixed.defaultProps = {
  position: `fixed`,
};

export const Sticky = styled(Box)([]);

Sticky.defaultProps = {
  position: `sticky`,
};

export const Absolute = styled(Box)([]);

Absolute.defaultProps = {
  position: `absolute`,
  zIndex: 0,
};
