import styled from "styled-components";
import { compose, layout, space, color, textAlign, border, position, grid, opacity, order } from "styled-system";

const Box = styled.div(
  compose(
    layout,
    space,
    color,
    textAlign,
    border,
    position,
    grid,
    opacity,
    order,
  ),
);

export default Box;
